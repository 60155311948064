import { Entity } from '@backstage/catalog-model';
import { createApiRef } from '@backstage/core-plugin-api';
import {
  EntityTimeseriesRequest,
  IssueTimeseriesResponse,
  SecurityIssue,
  StatisticsResponse,
} from '@internal/backstage-plugin-security-issues-common';

/**
 * @public
 */
export const securityIssuesApiRef = createApiRef<SecurityIssuesApi>({
  id: 'plugin.security-issues.service',
});

/**
 * @public
 */
export interface SecurityIssuesApi {
  getEntityOverview(entity: Entity): Promise<SecurityIssue[]>;
  getEntityStatistics(entity: Entity): Promise<StatisticsResponse>;
  getOverallIssues(): Promise<SecurityIssue[]>;
  getOverallStatistics(): Promise<StatisticsResponse>;
  getEntityTimeseries(
    entity: Entity,
    interval: EntityTimeseriesRequest['interval'],
  ): Promise<IssueTimeseriesResponse>;
  getAllTimeseries(
    interval: EntityTimeseriesRequest['interval'],
  ): Promise<IssueTimeseriesResponse>;
}

type ResponseErrorType = Error & { status: number };

export class ResponseError extends Error implements ResponseErrorType {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}
