import { AppTheme } from '@backstage/core-plugin-api';
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeOptions,
  UnifiedThemeProvider,
} from '@backstage/theme';
import DarkIcon from '@mui/icons-material/NightsStay';
import { lighten } from '@mui/material/styles';
import React from 'react';
import {
  colouredPageThemes,
  commonComponentOverrides,
  commonPalette,
  generateComponentStyles,
} from './defaultTheme';
import { synColors } from './synColors';

const { OFF_BLACK, OFF_WHITE, BLACK, YELLOW, BLACK_PAPER_ELEVATED } = synColors;

const darkPalette = {
  ...palettes.dark,
  ...commonPalette,
  ...{
    primary: { main: OFF_WHITE },
  },
};

const darkComponentOverrides: UnifiedThemeOptions['components'] = {
  ...commonComponentOverrides,
  ...{
    MuiButton: generateComponentStyles({
      containedPrimary: {
        backgroundColor: YELLOW,
        color: OFF_BLACK,
        fontWeight: 'bold',
      },
      contained: {
        backgroundColor: YELLOW,
        color: OFF_BLACK,
        fontWeight: 'bold',
      },
      outlinedPrimary: {
        backgroundColor: YELLOW,
        color: OFF_BLACK,
        fontWeight: 'bold',
      },
    }),
    MuiTableRow: generateComponentStyles({
      root: {
        backgroundColor: BLACK_PAPER_ELEVATED,
        border: `1px solid ${lighten(BLACK, 0.1)}`,
      },
    }),
    CatalogReactUserListPicker: generateComponentStyles({
      root: {
        paddingTop: '8px',
        backgroundColor: lighten(BLACK_PAPER_ELEVATED, 0.05),
        border: `1px solid ${lighten(BLACK_PAPER_ELEVATED, 0.2)}`,
      },
    }),
    BackstageSelectInputBase: generateComponentStyles({
      input: {
        border: `1px solid rgba(255, 255, 255, 0.23)`,
      },
    }),
    BackstageTableHeader: generateComponentStyles({
      header: {
        borderTop: `1px solid ${lighten(BLACK, 0.1)}`,
        borderBottom: `1px solid ${lighten(BLACK, 0.1)}`,
        backgroundColor: 'inherit',
      },
    }),
  },
};

const darkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: darkPalette,
  }),
  defaultPageTheme: 'home',
  fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
  pageTheme: colouredPageThemes,
  components: darkComponentOverrides,
});

export const darkSynechronTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = {
  id: 'Dark Theme',
  title: 'Dark Theme',
  variant: 'dark',
  icon: <DarkIcon />,
  Provider: ({ children }) => (
    <UnifiedThemeProvider theme={darkTheme} children={children} />
  ),
};
