import { alertApiRef, configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  CoderProvider as BaseCoderProvider,
  CoderAppConfig,
} from '@coder/backstage-plugin-coder';
import React, { PropsWithChildren } from 'react';

export const CoderProvider = ({ children }: PropsWithChildren) => {
  const config = useApi(configApiRef);
  const alertsApi = useApi(alertApiRef);

  let coderConfig;

  try {
    coderConfig = config.get('coder') as CoderAppConfig;
  } catch (e) {
    alertsApi.post({
      message: 'Valid coder plugin configuration not found',
      severity: 'error',
    });

    return null;
  }

  return (
    <BaseCoderProvider appConfig={coderConfig} fallbackAuthUiMode="hidden">
      {children}
    </BaseCoderProvider>
  );
};
