import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  IssueTimeseriesResponse,
  SecurityIssue,
  StatisticsResponse,
} from '@internal/backstage-plugin-security-issues-common';
import { ResponseError, SecurityIssuesApi } from './SecurityIssuesApi';

export class SecurityApiClient implements SecurityIssuesApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;
  private baseUrl: string | undefined;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  private async buildGet(endpoint: string, entity?: Entity) {
    this.baseUrl =
      this.baseUrl ?? (await this.discoveryApi.getBaseUrl('security-issues'));
    const cleanEntityRef = entity
      ? encodeURIComponent(stringifyEntityRef(entity))
      : undefined;

    return `${this.baseUrl}${endpoint}${
      cleanEntityRef ? `/${cleanEntityRef}` : ''
    }`;
  }

  private async createResponseError(resp: Response): Promise<ResponseError> {
    const error = new ResponseError(`Unable to load issue data.`, resp.status);

    if (resp.headers.get('Content-Type')?.includes('application/json')) {
      const json = await resp.json();
      if (json.error) error.message = json.error;
    }

    return error;
  }

  private async doRequest(
    input: string | URL | globalThis.Request,
    init?: RequestInit | undefined,
  ) {
    const resp = await this.fetchApi.fetch(input, init);
    if (!resp.ok) {
      const error = await this.createResponseError(resp);
      throw error;
    }

    return await resp.json();
  }

  async getEntityOverview(entity: Entity): Promise<SecurityIssue[]> {
    return await this.doRequest(`${await this.buildGet('/issues', entity)}`);
  }

  async getEntityStatistics(entity: Entity): Promise<StatisticsResponse> {
    return await this.doRequest(
      `${await this.buildGet('/statistics', entity)}`,
    );
  }

  async getEntityTimeseries(
    entity: Entity,
    interval = 'weekly',
  ): Promise<IssueTimeseriesResponse> {
    return await this.doRequest(
      `${await this.buildGet('/timeseries', entity)}?interval=${interval}`,
    );
  }

  async getOverallIssues(): Promise<SecurityIssue[]> {
    return await this.doRequest(`${await this.buildGet('/issues')}`);
  }

  async getOverallStatistics(): Promise<StatisticsResponse> {
    return await this.doRequest(`${await this.buildGet('/statistics')}`);
  }

  async getAllTimeseries(
    interval = 'weekly',
  ): Promise<IssueTimeseriesResponse> {
    return await this.doRequest(
      `${await this.buildGet('/timeseries')}?interval=${interval}`,
    );
  }
}
