import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { UnprocessedEntitiesContent } from '@backstage/plugin-catalog-unprocessed-entities';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import {
  CoderProvider,
  SynechronNavigation,
} from '@internal/backstage-plugin-synechron-navigation-react';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { entityPage } from './components/entities/EntityPage';

import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  ConfigContent,
  DevToolsLayout,
  DevToolsPage,
  InfoContent,
} from '@backstage/plugin-devtools';
import { VisitListener } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { HomePage } from './components/home/HomePage';
import * as plugins from './plugins';

import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';
import { SecurityOverviewPage } from '@internal/backstage-plugin-security-issues';
import { SynechronAppsPage } from '@internal/backstage-plugin-synechron-apps';
import { SynechronSignInPage } from '@internal/backstage-plugin-synechron-signin-react';
import {
  darkSynechronTheme,
  lightSynechronTheme,
} from '@internal/backstage-plugin-synechron-theme-react';
import { genCatalogTableColumns } from './components/catalog/table/columns/genCatalogTableColumns';
import { genDocsTableColumns } from './components/catalog/table/columns/genDocsTableColumns';
import { TableWithInjectedStyles } from './components/catalog/table/TableWithInjectedStyles';
import { CustomGitlabPickerFieldExtension } from './components/scaffolder/fields/CustomGitlabRepoPicker';
import { searchPage } from './components/search/SearchPage';

const app = createApp({
  apis,
  featureFlags: [
    {
      pluginId: '',
      name: 'experimental-colours',
      description: 'Enables some experimental colours and styles',
    },
  ],
  plugins: Object.values(plugins),
  components: {
    SignInPage: SynechronSignInPage,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [darkSynechronTheme, lightSynechronTheme],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route
      path="/catalog"
      element={
        <TableWithInjectedStyles>
          <CatalogIndexPage
            initiallySelectedFilter="all"
            columns={genCatalogTableColumns}
          />
        </TableWithInjectedStyles>
      }
    />
    <Route
      path="/systems"
      element={<Navigate to="/catalog?filters%5Bkind%5D=system" />}
    />
    <Route
      path="/components"
      element={<Navigate to="/catalog?filters%5Bkind%5D=component" />}
    />

    <Route
      path="/infrawallet"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <InfraWalletPage title="Cloud Costs" subTitle="InfraWallet" />
        </RequirePermission>
      }
    />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs"
      element={
        <TableWithInjectedStyles>
          <TechDocsIndexPage
            initialFilter="all"
            columns={genDocsTableColumns()}
          />
        </TableWithInjectedStyles>
      }
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <CustomGitlabPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route
      path="/api-docs"
      element={
        <TableWithInjectedStyles>
          <ApiExplorerPage
            initiallySelectedFilter="all"
            // @ts-ignore - ApiExplorerPage DOES accept a function for columns just like the catalog page, but it's not typed
            columns={genCatalogTableColumns}
          />
        </TableWithInjectedStyles>
      }
    />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          title="Skills Radar"
          subtitle="We should aim for at least 'Novice' level when working on a project."
          pageTitle="Cloud & DevOps"
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/homepage" element={<HomePage />} />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      <DevToolsLayout>
        <DevToolsLayout.Route path="info" title="Info">
          <InfoContent />
        </DevToolsLayout.Route>
        <DevToolsLayout.Route path="config" title="Config">
          <ConfigContent />
        </DevToolsLayout.Route>
        <DevToolsLayout.Route
          path="unprocessed-entities"
          title="Unprocessed Entities"
        >
          <UnprocessedEntitiesContent />
        </DevToolsLayout.Route>
      </DevToolsLayout>
    </Route>
    <Route path="/ai-apps" element={<SynechronAppsPage />} />
    <Route path="/issues-overview" element={<SecurityOverviewPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <CoderProvider>
        <VisitListener />
        <SynechronNavigation>{routes}</SynechronNavigation>
      </CoderProvider>
    </AppRouter>
  </>,
);
