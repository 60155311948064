import {
  createApiFactory,
  createPlugin,
  createReactExtension,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { securityIssuesApiRef } from './api/SecurityIssuesApi';
import { SecurityApiClient } from './api/SecurityIssuesApiClient';
import { rootRouteRef } from './routes';

export const securityIssuesPlugin = createPlugin({
  id: 'security-issues',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [
    {
      name: 'security-issues-timeseries-overview',
    },
  ],
  apis: [
    createApiFactory({
      api: securityIssuesApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new SecurityApiClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const SecurityOverviewPage = securityIssuesPlugin.provide(
  createRoutableExtension({
    name: 'SecurityOverviewPage',
    component: () =>
      import('./components/pages/SecurityOverviewPage').then(
        m => m.SecurityOverviewPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const SecurityIssuesHomepageCard = securityIssuesPlugin.provide(
  createReactExtension({
    component: {
      lazy: () =>
        import('./components/cards/TotalIssuesCard').then(m => m.HomePageCard),
    },
  }),
);

export const SecurityIssuesEntityOverviewTab = securityIssuesPlugin.provide(
  createReactExtension({
    component: {
      lazy: () =>
        import('./components/pages/EntityOverviewPage').then(
          m => m.EntityOverviewTab,
        ),
    },
  }),
);
