import { AppTheme } from '@backstage/core-plugin-api';
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  UnifiedThemeOptions,
  UnifiedThemeProvider,
} from '@backstage/theme';
import LightIcon from '@mui/icons-material/WbSunny';
import { darken } from '@mui/material/styles';
import React from 'react';
import {
  colouredPageThemes,
  commonComponentOverrides,
  commonPalette,
  generateComponentStyles,
} from './defaultTheme';
import { synColors } from './synColors';

const { OFF_WHITE, BLACK, WHITE } = synColors;

const lightPalette = {
  ...palettes.light,
  ...commonPalette,
  ...{
    primary: {
      main: BLACK,
    },
    secondary: {
      main: OFF_WHITE,
    },
    background: {
      default: OFF_WHITE,
      paper: WHITE,
    },
  },
};

const lightComponentOverrides: UnifiedThemeOptions['components'] = {
  ...commonComponentOverrides,
  ...{
    MuiCard: generateComponentStyles({
      root: { background: `${WHITE}` },
    }),
    MuiCardActions: generateComponentStyles({ root: { color: WHITE } }),
    MuiTableRow: generateComponentStyles({
      root: {
        backgroundColor: `${WHITE}`,
        borderBottom: `1px solid ${darken(WHITE, 0.1)}`,
      },
    }),
    // @ts-ignore This component is not typed but is exported by backstage
    CatalogReactUserListPicker: generateComponentStyles({
      root: {
        paddingTop: '8px',
        backgroundColor: darken(WHITE, 0.05),
        border: `1px solid ${darken(WHITE, 0.2)}`,
      },
    }),
  },
};

const lightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: lightPalette,
  }),
  defaultPageTheme: 'home',
  fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
  pageTheme: colouredPageThemes,
  components: lightComponentOverrides,
});

export const lightSynechronTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> =
  {
    id: 'Light Theme',
    title: 'Light Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={lightTheme} children={children} />
    ),
  };
