import { EntityLayout } from '@backstage/plugin-catalog';
import { SecurityIssuesEntityOverviewTab } from '@internal/backstage-plugin-security-issues';
import React from 'react';
import { commonOverviewContent, commonRoutes } from './common';

export const componentPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {commonOverviewContent}
    </EntityLayout.Route>
    {...commonRoutes}
    <EntityLayout.Route path="/issues" title="Issues">
      <SecurityIssuesEntityOverviewTab />
    </EntityLayout.Route>
  </EntityLayout>
);
