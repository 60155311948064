import { Entity } from '@backstage/catalog-model';

export const isDependenciesAvailable = (entity: Entity) =>
  Boolean(entity.relations?.some(relation => relation.type === 'dependsOn'));

export const isApiAvailable = (entity: Entity) =>
  Boolean(entity.relations?.some(relation => relation.type === 'providesApi'));

export const isLinksAvailable = (entity: Entity) =>
  Boolean(entity.metadata.links?.length);

export const isCoderAvailable = async (entity: Entity) => {
  return Boolean(entity.spec?.coder);
  // return Boolean(['Template', 'Component'].includes(entity.kind)); Later, we want to show coder for all Component and Template entities even if they don't have a spec.coder field
};
